<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="11">
        <h4>SFTP User Creation</h4>
      </b-col>
      <b-col>
        <b-button @click="submit" :disabled="loading || $v.$invalid" variant="primary" class="float-right">
          <feather type="save"></feather>
          Submit
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>User Type</b>
            </b-col>
            <b-col cols="6">
              <b-form-select v-model="server" :options="servers"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Username</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.username" :state="!$v.user.username.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>First Name</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.additional_info.firstname" :state="!$v.user.additional_info.firstname.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Last Name</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.additional_info.lastname" :state="!$v.user.additional_info.lastname.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-if="server === 'external'">
            <b-col cols="2">
              <b>Company</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.additional_info.company" :state="!$v.user.additional_info.company.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-if="server === 'external'">
            <b-col cols="2">
              <b>Root Folder</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.filesystem.key_prefix" :state="!$v.user.filesystem.key_prefix.$invalid"/>
            </b-col>
          </b-row>
          <b-row v-if="server === 'internal'" class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <p class="card-text"><b>Groups</b></p>
            </b-col>
            <b-col cols="6">
              <multiselect :max="2" :multiple="true" v-model="user.groups" :options="options"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-if="server === 'external'">
            <b-col cols="2">
              <b>Emails</b>
            </b-col>
            <b-col cols="6">
              <b-row v-for="(_, idx) in user.emails" :key="idx" class="mb-2">
                <b-col cols="11">
                  <b-form-input v-model="user.emails[idx]" :state="!$v.user.emails.$each[idx].$invalid"/>
                </b-col>
                <b-col cols="1" class="pl-0">
                  <b-button @click="removeEmail(idx)"
                            variant="danger" size="md"><feather type="trash"></feather></b-button>
                </b-col>
              </b-row>
              <div class="mt-2 mb-2">
                <b-button @click="addNewEmail" variant="primary" size="md"><feather type="plus"></feather></b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-else>
            <b-col cols="2">
              <b>Email</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.email" :state="!$v.user.email.$invalid"/>
            </b-col>
          </b-row>
          <p class="card-text"><b>Public Keys:</b></p>
          <div class="ml-2 mb-3" v-if="user.publicKeys">
            <b-row v-for="(publicKey, idx) in user.publicKeys" :key="idx">
              <b-col cols="7">
                <b-textarea rows="4" :value="publicKey" :state="!$v.user.publicKeys.$each[idx].$invalid"/>
              </b-col>
              <b-col cols="1">
                <b-button @click="removePublicKey(idx)" variant="danger" size="md"><feather type="trash"></feather></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="1">
                <b-button @click="addNewPublicKey" variant="primary" size="md"><feather type="plus"></feather></b-button>
              </b-col>
            </b-row>
          </div>
          <p class="card-text"><b>Permissions</b></p>
          <div class="ml-2">
            <b-row v-for="(permission, idx) in user.permissions" :key="idx" class="mt-1 d-flex align-items-center">
              <b-col cols="3">
                <b-form-group
                  :id="`label-permission-path-${idx}`"
                  label="Folder Path"
                  invalid-feedback="Ex: /test/simpsons/"
                  :label-for="`permission-path-${idx}`">
                  <b-form-input
                    :id="`permission-path-${idx}`"
                    v-model="permission.path"
                    :state="!$v.user.permissions.$each[idx].path.$invalid"
                    type="text"
                    placeholder="Enter filepath"
                    required/>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group
                  :id="`label-permission-role-${idx}`"
                  label="Role"
                  invalid-feedback="You need to choose a role"
                  :label-for="`permission-role-${idx}`">
                  <b-form-select
                    :id="`permission-role-${idx}`"
                    :state="!$v.user.permissions.$each[idx].role.$invalid"
                    v-model="permission.role"
                    :options="roles"/>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-button @click="removePermission(idx)" variant="danger" size="md"><feather type="trash"/></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="1">
                <b-button @click="addNewPermission" variant="primary" size="md"><feather type="plus"/></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="$v.user.permissions.$invalid && user.permissions.length === 0">
              <b-col cols="12">
                <p class="invalid-feedback" style="display: block">At least one permission is required</p>
              </b-col>
            </b-row>
          </div>
          <b-row class="d-flex align-items-center mt-3">
            <b-col cols="2">
              <b>Generate Password</b>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                id="user-password"
                v-model="user.create_password" :state="!$v.user.create_password.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Active</b>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                id="user-status"
                v-model="user.status" :state="!$v.user.status.$invalid"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import { required, email, minLength } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import { parseValidationsStr } from '@sword-health/input-validation';

export default {
  name: 'SFTPUserCreation',
  mixins: [ errorHandler ],
  components: {
    Multiselect,
  },
  data() {
    return {
      servers: [
        { value: 'internal', text: 'Internal' },
        { value: 'external', text: 'External' },
      ],
      loading: false,
      server: 'internal',
      user: {
        username: null,
        email: null,
        emails: [ '' ],
        status: true,
        create_password: true,
        filesystem: {
          key_prefix: null,
        },
        additional_info: {
          firstname: null,
          lastname: null,
          company: null,
        },
        permissions: [],
        publicKeys: [],
        groups: [],
      },
      options: [],
      roles: [
        { value: 'admin', text: 'Admin' },
        { value: 'write', text: 'Write' },
        { value: 'read', text: 'Read' },
        { value: 'deny', text: 'Deny' },
      ],
    };
  },
  validations() {
    return this.rules;
  },
  async beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
      return;
    }

    await this.getGroups();
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
    rules() {
      const cfg = {
        user: {
          username: {
            required,
            'Is not allow user name has: spaces and special characters (just dot and underscore)':
              v => !(v === '' || v === null) && v.match(/^[a-z][a-z0-9._]*$/i) !== null,
          },
          create_password: { required },
          status: { required },
          additional_info: {
            firstname: { required },
            lastname: { required },
          },
          permissions: {
            $each: {
              path: {
                isUnique(value) {
                  if (value === '') {
                    return true;
                  }

                  return this.user.permissions.filter(o => o.path === value).length <= 1;
                },
                ...parseValidationsStr({ required, validChars: /(^\/.*\/$|^\/$)/ }),
              },
              role: { required },
            },
            required,
            minLength: minLength(1),
          },
          publicKeys: {
            $each: {
              required,
              isUnique(value) {
                if (value === '') {
                  return true;
                }

                return this.user.publicKeys.filter(o => o === value).length <= 1;
              },
            },
          },
        },
      };

      if (this.server === 'external') {
        cfg.user.filesystem = {
          key_prefix: {
            required,
            ...parseValidationsStr({ required, validChars: /^[^\\/].*\/$/ }),
          },
        };

        cfg.user.additional_info.company = { required };

        cfg.user.emails = {
          $each: {
            required,
            email,
            isUnique(value) {
              if (value === '') {
                return true;
              }

              return this.user.emails.filter(o => o === value).length <= 1;
            },
          },
          minLength: minLength(1),
        };
      } else {
        cfg.user.email = {
          required,
          email,
        };
      }

      return cfg;
    },
  },
  methods: {
    addNewPermission() {
      this.user.permissions.push({ path: '', role: '' });
    },
    removePermission(idx) {
      this.user.permissions.splice(idx, 1);
    },
    addNewPublicKey() {
      this.user.publicKeys.push('');
    },
    removePublicKey(idx) {
      this.user.publicKeys.splice(idx, 1);
    },
    addNewEmail() {
      this.user.emails.push('');
    },
    removeEmail(idx) {
      this.user.emails.splice(idx, 1);
    },
    async getGroups() {
      const { data } = await this.$store.dispatch('Sftp/Groups/getGroups', { server: this.server });
      data.forEach(group => this.options.push(group.name));
    },
    async submit() {
      if (this.$v.$invalid) {
        this.$noty.error('Please fill out all required fields');
        return;
      }

      if (this.server === 'internal') {
        this.user.emails = [ this.user.email ];
      }

      try {
        this.loading = true;
        const payload = {
          server: this.server,
          body: {
            username: this.user.username,
            email: this.user.email,
            create_password: this.user.create_password,
            additional_info: JSON.stringify({
              firstname: this.user.additional_info.firstname,
              lastname: this.user.additional_info.lastname,
              company: this.user.additional_info.company,
              email: this.user.email,
              emails: this.user.emails,
            }),
            public_keys: this.user.publicKeys,
            permissions: this.user.permissions.reduce((acc, { path, role }) => {
              acc[path] = role;
              return acc;
            }, {}),
            status: this.user.status ? 1 : 0,
          },
        };

        if (this.server === 'internal') {
          payload.body.groups = [];
          let i = 1;
          this.user.groups.forEach(group => {
            payload.body.groups.push({ name: group, type: i });
            i++;
          });
        } else {
          payload.body.filesystem = {
            key_prefix: this.user.filesystem.key_prefix,
          };
        }

        await this.$store.dispatch('Sftp/User/createUser', payload);
        this.$noty.success('User created successfully');
        this.$router.push(`/sftp/users/${this.server}/${this.user.username}`);
        return;
      } catch (err) {
        console.error(err);
        this.$noty.error(`Failed to create user: ${err?.response?.data?.message || err.message}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
